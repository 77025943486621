// src/GlossaryPage.js
import React from 'react';
import classes from './startup.module.css';
import img from "./img.jpg";  
import img1 from './img1.jpg'; 
import img2 from './img2.jpg'; 
import pic from "./boy.png";

const GlossaryPage = () => {
    // Updated About Domainera Content
    const basicInfo = {
        title: "About Domainera",
        description: [
            "Domainera is an insightful and dynamic web blog page dedicated to educating readers about the latest developments in technology, specifically focusing on Artificial Intelligence (AI), Blockchain, Cybersecurity, and Tech-Related Disputes.",
            "AI Innovations and Insights: Domainera keeps readers informed about the rapidly evolving world of AI, exploring its applications, ethical implications, and impact on industries like healthcare, finance, and education. It provides readers with the knowledge they need to understand AI's role in modern society.",
            "Blockchain & Cryptocurrency: With blockchain transforming financial systems and beyond, Domainera sheds light on blockchain fundamentals, its uses in various sectors, cryptocurrency trends, and emerging regulations. This section is perfect for anyone interested in understanding decentralized systems and digital assets.",
            "Cybersecurity Awareness: In a world where digital threats are becoming more sophisticated, Domainera’s cybersecurity articles aim to empower individuals and organizations by offering practical tips and news on data protection, privacy, and threat management. The platform emphasizes the importance of secure technology practices.",
            "Tech Disputes & Legal Aspects: Covering high-profile tech disputes, patent wars, regulatory changes, and ethical controversies, Domainera provides a balanced view of the challenges and conflicts that shape the tech landscape. This section is ideal for readers interested in the legal and ethical facets of technology.",
            "Everyday Tech: For practical technology users, Domainera also shares helpful content on everyday tech topics. This includes gadget reviews, software tutorials, and user-friendly explanations of complex concepts, making technology accessible and relevant to daily life.",
            "With its blend of in-depth analysis, news updates, and accessible articles, Domainera is committed to empowering people by educating them about the technologies that drive the future."
        ]
    };

    const images = [img, img1, img2, img1, img2, img1];

   
    const testimonials = [
        {
            name: "Rakesh Verma",
            feedback: "Domainera provided excellent service and support throughout the process. Highly recommend",
            image: pic
        },
        {
            name: "Ravichandra Pradhan",
            feedback: "The team at Domainera is knowledgeable and responsive. My website looks fantastic",
            image: pic
        },
    ];

    return (
        <div className={classes.glossarypage}>
            <h1>{basicInfo.title}</h1>
            <div className={classes.basicinfo}>
                {basicInfo.description.map((text, index) => (
                    <p key={index}>{text}</p>
                ))}
            </div>

            <div className={classes.imagegallery}>
                <h2>Image Gallery</h2>
                <div className={classes.images}>
                    {images.map((src, index) => (
                        <img key={index} src={src} alt={`Gallery Image ${index + 1}`} className={classes.galleryImage} />
                    ))}
                </div>
            </div>

            <div className={classes.testimonials}>
                <h2>What Our Clients Say</h2>
                <div className={classes.testimonialContainer}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className={classes.testimonial}>
                            <img src={testimonial.image} alt={testimonial.name} className={classes.clientImage} />
                            <h4>{testimonial.name}</h4>
                            <p>"{testimonial.feedback}"</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GlossaryPage;
